/* CustomToast.css */

.custom-toast-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  width: fit-content;
}

.custom-toast {
  display: flex;
  align-items: center;
  min-height: 60px;
  padding: 15px 30px;
  background-color: #28a745; /* Green background for success message */
  color: #fff; /* White text for better visibility */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  font-weight: 500;
}

.custom-toast-error {
  background-color: #dc3545; /* Red background for error message */
}
