/* CustomerList.css */

/* Overlay for the modal */
.modal-overlay {
    position: fixed; /* Fix the modal overlay to the screen */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    z-index: 9999; /* Make sure it's on top of other elements */
  }
  
  /* Container for the modal content */
  .modal-container {
    width: 500px; /* Define a width for the modal */
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    animation: fadeIn 0.3s ease-in-out;
  }
  
  /* Close button styles */
  .close-button {
    position: absolute;
    top: 15px;
    right: 20px;
    font-size: 24px;
    color: #333;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .close-button:hover {
    color: #d9534f;
  }
  
  /* Animation for modal appearance */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Form input field styling */
  .modal-form .form-group {
    margin-bottom: 20px;
  }
  
  .modal-form label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
  }
  
  .modal-form input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
  }
  
  /* Button styles */
  .modal-footer .btn-submit {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .modal-footer .btn-submit:hover {
    background-color: #0056b3;
  }
  
  .modal-footer .btn-cancel {
    background-color: #6c757d;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    margin-left: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .modal-footer .btn-cancel:hover {
    background-color: #5a6268;
  }
  